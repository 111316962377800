<template>
  <div>
    <b-row align-v="center">
      <b-col lg="7">
        <div v-html="$t('home.summary-close')"></div>
      </b-col>
      <b-col lg="5">
        <img
          alt=""
          class="mb-5 w-100"
          :src="`${$t('baseurl')}/img/opengraph/home.jpg`"
          title="Alternatives - David Revoy - Creative Commons By"
        />
      </b-col>
    </b-row>

    <h2
      v-html="$t('home.title-alt')"
    ></h2>
    <b-row
      id="list-services"
      align-h="center"
    >
      <b-col
        v-for="k in Object.keys($t('home.list'))"
        :id="$t(`txt.${k}`, '-l')"
        :key="k"
        sm="6"
        lg="4"
        class="mb-4"
      >
        <router-link :to="$t(`txt.${k}`, '-lL')">
          <div class="card h-100">
            <div class="row no-gutters h-100">
              <div class="col-3">
                <div class="card-body text-right pt-3 pl-3 pr-2">
                  <i :class="`${$t(`icon.${k}`)} fa-2x d-block`"></i>
                </div>
              </div>
              <div class="col-9">
                <div class="card-body h-100 pt-3 pl-2 pr-3 pb-3 d-flex flex-column">
                  <h3
                    class="card-title h4 mb-1"
                    v-html="$t(`color.${k}`)"
                  ></h3>
                  <p
                    class="card-text h6 mb-3"
                    v-html="$t(`home.list.${k}`)"
                  ></p>
                  <p
                    v-if="['story','maestro','bookin'].includes(k)"
                    class="card-text text-warning small mt-auto mb-0"
                    v-html="$t('home.noalt')"
                  ></p>
                  <p
                    v-else
                    class="card-text mb-0 mt-auto"
                  >
                    <span class="fd-g4 fb-g3 rounded btn-sm d-inline-flex align-items-center">

                      <i class="fas fa-arrow-right fa-pull-left"></i>
                      <span v-html="$t('home.btn.alt')"></span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </b-col>
    </b-row>

    <h2 v-html="$t('home.title-open')"></h2>

    <b-row align-v="center">
      <b-col
        lg="6"
      >
        <div v-html="$t('home.summary-open')"></div>
      </b-col>
      <b-col
        lg="6"
      >
        <p class="text-center m-0">
          <b-button
            :href="$t('link.dio')"
            size="lg"
            variant="primary h-100 d-inline-flex align-items-center my-4"
          >
            <span v-html="$t('home.btn.dio')"></span>
            <i class="fas fa-lg fa-arrow-right fa-pull-right ml-4"></i>
          </b-button>
        </p>
      </b-col>
    </b-row>

    <p
      class="signature text-right mt-4 mb-0"
      v-html="$t('home.update', {date : $t('meta.date-edit')})"
    ></p>
  </div>
</template>

<script>
export default {
  methods: {
    date(date) {
      return this.$t(new Date(date).toLocaleDateString(this.$t('lang'), {
        year: 'numeric',
        month: 'long',
      }), '-K');
    },
  },
};
</script>

<style lang="scss">
#home {
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid var(--f-g3);
  }

  img {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 500px;
    display: block;
    margin: 0 auto;
  }

  li:target {
    border: 3px solid #edd8fd;
    border-radius: 5px;
    margin: 5px;
  }

  .table {
    td {
      text-align: center;
      vertical-align: middle;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }

    li {
      padding: 1rem;
    }

    @media (min-width: 768px) {
      li {
        min-width: 170px;
      }
    }
  }

  #limit {
    td:nth-child(2) {
      border-top: 4px solid var(--f-o4);
    }
    td:nth-child(3) {
      border-top: 4px solid var(--f-b4);
    }
  }

  #close {
    td:nth-child(2) {
      border-top: 4px solid var(--f-j3);
    }
    td:nth-child(3) {
      border-top: 4px solid var(--f-o4);
    }
    td:nth-child(4) {
      border-top: 4px solid var(--f-b9);
    }
  }
}

.fa-2x::before,
a .fa-2x::before {
  color: var(--f-g6) !important;
}

a:hover, a:focus {
  .fa-2x::before {
    color: var(--f-g7) !important;
  }
}

#list-services {
  a {
    .card {
      color: var(--f-g9);
      border-color:  var(--f-g3);
    }

    &:hover, &:focus {
      text-decoration: none !important;
      .card {
        background: var(--f-g1);
      }
    }
  }
}
</style>
