var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('close.header'))}}),_vm._v(" "),_c('b-card',{attrs:{"id":"alt"},domProps:{"innerHTML":_vm._s(_vm.$t('news'))}}),_vm._v(" "),_c('h3',{domProps:{"innerHTML":_vm._s(_vm.$t('close.more'))}}),_vm._v(" "),_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('close.doc', {
        link: 'https://docs.framasoft.org/fr/ttrss/deframasoftiser.html',
      }))}}),_vm._v(" "),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('close.chatons', {
        link: 'https://chatons.org/search/by-service?service_type_target_id=129'
      }))}}),_vm._v(" "),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('close.blog'))}}),_vm._v(" "),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('close.cloud', {
        link: `${this.$t('cloud.news')}`
      }))}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }