/* eslint-disable global-require */
/* eslint-disable import/no-commonjs */
function formatWiki(env, data) {
  const wiki = {};
  const json = (data !== undefined && data.length > 0)
    ? data
    : require('../public/wiki.json'); // eslint-disable-line

  for (let i = 0; i < json.length; i += 1) {
    const front = json[i].content.match(/^``` ?ya?ml([\s\S]*)```/m)
      ? json[i].content.split('```')[1]
      : '';

    const lg = front.match(/lang: ([a-zA-Z_]+)/)
      ? front.match(/lang: ([a-zA-Z_]+)/)[1]
      : '';

    const type = front.match(/type: ([a-z]+)/)
      ? front.match(/type: ([a-z]+)/)[1]
      : '';

    const title = front.match(/title: (.+)/)
      ? front.match(/title: (.+)/)[1] /* alternative title */
      : json[i].title; /* tile from .md filename (- and / are not allowed) */

    let img = front.match(/img: (.+)/)
      ? front.match(/img: (.+)/)[1]
      : '';

    /* settings for news */
    const date = front.match(/date: ([0-9]{2}-[0-9]{2}-[0-9]{2})/)
      ? front.match(/date: ([0-9]{2}-[0-9]{2}-[0-9]{2})/)[1]
      : '';

    let url = front.match(/url: (.+)/)
      ? front.match(/url: (.+)/)[1]
      : '';

    /* setting for FAQ */
    const id = front.match(/id: ([a-zA-Z0-9-]+)/)
      ? front.match(/id: ([a-zA-Z0-9-]+)/)[1]
      : '';

    const cat = front.match(/cat: ([a-zA-Z-]+)/)
      ? front.match(/cat: ([a-zA-Z-]+)/)[1]
      : '';

    const rank = front.match(/rank: ([0-9]+)/)
      ? front.match(/rank: ([0-9]+)/)[1]
      : 99;

    /* Format content */
    const content = json[i].content
      // Replace wiki relative links by local links
      .replace(/\]\(uploads\//gm, `](${env.url}wiki/`)
      // Remove front matter
      .replace(`\`\`\`${front}\`\`\``, '');

    /* Extract data */
    if (content.match(/^!\[.*\]\((.*?)\)/m)) {
      [img] = [content.match(/^!\[.*\]\((.*?)\)/m)[1]];
    } // else keep img setting in front matter

    const text = content
      .replace(/^(!\[.*\]\((.*)\))/m, '') // remove img
      .replace(/^[\s]*\n+/m, ''); // remove spaces & newlines at the begining;

    if (wiki[lg] === undefined) {
      wiki[lg] = {};
    }
    if (wiki[lg][type] === undefined) {
      wiki[lg][type] = {};
    }

    /* News */
    if (/^(news)/.test(type)) {
      if (url === '') {
        url = `${env.url}news#${date}`;
      }

      if (wiki[lg][type].posts === undefined) {
        wiki[lg][type].posts = {};
      }
      wiki[lg][type].posts[date] = {};
      Object.assign(wiki[lg][type].posts[date], {
        id,
        img,
        url,
        title,
        text,
        edit: json[i].slug,
      });
    }

    /* FAQ */
    if (/^faq$/.test(type) && cat !== '' && id !== '') {
      if (wiki[lg][type].ranking === undefined) {
        wiki[lg][type].ranking = {};
      }
      if (wiki[lg][type][cat] === undefined) {
        wiki[lg][type][cat] = {};
        wiki[lg][type].ranking[cat] = [];
      }
      wiki[lg][type][cat][id] = {
        q: title,
        a: text,
        e: json[i].slug,
      };
      wiki[lg][type].ranking[cat].push([rank, id]);
    }
  }

  /* sort FAQ questions by rank */
  Object.keys(wiki).forEach((lg) => {
    if (wiki[lg].faq !== undefined) {
      Object.keys(wiki[lg].faq.ranking).forEach((key) => {
        const mapped = wiki[lg].faq.ranking[key].sort((a, b) => a[0] - b[0]).map(x => x[1]);
        wiki[lg].faq.ranking[key] = mapped;
      });
    }
  });

  return wiki;
}

module.exports = formatWiki;
