import Vue from 'vue';
import VueI18n from 'vue-i18n';
import data from './data';

import merge from '../commons/plugins/merge';
import text from '../commons/plugins/text';

Vue.use(VueI18n);

Vue.use(merge);
Vue.use(text);

const config = process.env.CONFIG;
const wiki = (config.wiki.api !== '')
  ? require('./wiki')(config) // eslint-disable-line
  : {};

const lang = window.location.href
  .replace(`${window.location.host}/${config.base}`, '')
  .split('/')[(config.base === '') ? 2 : 3]
  .substr(0, 2)
  .toLowerCase() || '';
document.getElementsByTagName('html')[0].setAttribute('lang', lang);

const userLang = navigator.languages
  || [navigator.language || navigator.userLanguage];
let defaultRouteLang = '';

const messages = {};
const numberFormats = {};

messages.translations = config.translations.all;
messages.translations.available = config.translations.available;
let msg = {};
let req = {};

// Import translations
for (let i = 0; i < config.translations.available.length; i += 1) {
  const k = config.translations.available[i];
  /* eslint-disable */
  messages[k] = {};
  messages[k].lang = k;

  numberFormats[k] = {};
  numberFormats[k].eur = {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  };

  // Import data
  msg = {txt: {}, html: {}};
  msg = merge.$(msg, JSON.parse(JSON.stringify(data)));

  if (config.translations[k].includes('main')) {
    // Init with ../commons/translations/lg/main.yml
    req = require(`../commons/translations/${k}/main.yml`) || {};
    msg = merge.$(msg, JSON.parse(JSON.stringify(req)));
    // then import translations/lg/main.yml
    req = require(`./translations/${k}/main.yml`) || {};
    msg = merge.$(msg, JSON.parse(JSON.stringify(req)));
  }

  if (config.translations[k].includes('main2')) {
    req = require(`./translations/${k}/main2.yml`) || {};
    msg = merge.$(msg, JSON.parse(JSON.stringify(req)));
  }

  // translations/lg/*.yml
  for (let i = 0; i < config.translations[k].length; i += 1) {
    const file = config.translations[k][i];
    if (!/^main$/.test(file)) {
      msg[file] = msg[file] || {};
      req = require(`./translations/${k}/${file}.yml`) || {};
      msg[file] = merge.$(msg[file], JSON.parse(JSON.stringify(req)));
      if (/2$/.test(file)) {
        msg[file.replace(/2$/, '')] = merge.$(msg[file.replace(/2$/, '')], JSON.parse(JSON.stringify(req)));
      }
    }
  }

  // Copy wiki
  if (wiki[k] !== undefined) {
    msg = merge.$(msg, wiki[k]);
  }

  Object.keys(msg.color).forEach((j) => {
    if (msg.txt[j] === undefined) {
      msg.txt[j] = text.textTransform(msg.color[j], '-t');
    }
  });

  Object.keys(msg.link).forEach((j) => {
    if (msg.html[j] === undefined) {
      if (msg.color[j] !== undefined) {
        msg.html[j] = `<a href="@:link.${j}">@:color.${j}</a>`;
      } else if (msg.txt[j] !== undefined) {
        msg.html[j] = `<a href="@:link.${j}">@:txt.${j}</a>`;
      }
    }
  });

  messages[k] = merge.$(messages[k], msg);
  /* eslint-enable */
}
// define defaultRouteLang
for (let j = 0; j < userLang.length; j += 1) { // check if user langs
  const lg = userLang[j].substring(0, 2).toLowerCase();
  if (defaultRouteLang === '' && Object.keys(config.translations).includes(lg)) { // matches with translations
    defaultRouteLang = lg;
  }
}

messages.base = `${__dirname}${config.base}`;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang === '' ? defaultRouteLang : lang,
  fallbackLocale: config.translations.default,
  messages,
  numberFormats,
  silentTranslationWarn: true,
});

export default i18n;
