<template>
  <div>
    <div v-html="$t('close.header')"></div>

    <b-card
      id="alt"
      v-html="$t('bee')"
    />

    <h3 v-html="$t('close.more')"></h3>
    <ul>
      <li v-html="$t('close.chatons', {link: chatons})"></li>
      <li v-html="$t('close.blog')"></li>
      <li v-html="$t('close.cloud', {link: cloud})"></li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chatons: 'https://chatons.org/search/by-service?service_type_target_id=123',
      cloud: `${this.$t('cloud.bee')}`,
    };
  },
};
</script>

