var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('close.header'))}}),_vm._v(" "),_c('b-card',{attrs:{"id":"alt"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('drop'))}}),_vm._v(" "),_c('b-alert',{attrs:{"show":"","variant":"warning mb-0 mt-4 d-md-flex align-items-center border-0 rounded-0"}},[_c('img',{staticClass:"rounded-circle d-block mx-auto",staticStyle:{"border":"3px solid #645e5c","width":"70px","height":"70px"},attrs:{"src":`${_vm.$t('baseurl')}img/entraide/drop.svg`,"alt":""}}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('entraide'))}})])],1),_vm._v(" "),_c('h3',{domProps:{"innerHTML":_vm._s(_vm.$t('close.more'))}}),_vm._v(" "),_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('close.doc', {
        link: 'https://docs.framasoft.org/fr/lufi/deframasoftiser.html',
      }))}}),_vm._v(" "),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('close.chatons', {
        link: 'https://chatons.org/search/by-service?service_type_target_id=148'
      }))}}),_vm._v(" "),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('close.blog'))}}),_vm._v(" "),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('close.cloud', {
        link: `${this.$t('cloud.drop')}`
      }))}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }