<template>
  <header id="header">
    <div class="float-right">
      <I18n />
    </div>
    <h1
      v-html="$te(`meta.${this.$route.meta.id}.title`)
        ? $t(`meta.${this.$route.meta.id}.title`)
        : $t('meta.title')"
    ></h1>
    <hr
      class="trait"
      role="presentation"
    />
  </header>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },

  mounted() {
    this.randomAlt();
  },

  updated() {
    this.randomAlt();
  },

  methods: {
    randomAlt() {
      const ul = document.querySelector('#alt ul');
      if (ul) {
        for (let i = ul.children.length; i >= 0; i -= 1) {
          ul.appendChild(ul.children[Math.floor(Math.random() * i)]);
        }
      }
    },
  },
};
</script>

<style lang="scss">
  #header {
    background: #fff;
    // border-bottom: 1px solid var(--f-g4);

    h1 {
      margin: 0;
      line-height: normal;
      a {
        text-decoration: none;
      }
    }

    .btn {
      border: transparent;
    }
  }
</style>
